<template>
  <router-link to="/privacy#toc">
    <CircularButton text="TOC" class='toc-button'/>
  </router-link>

<div class="grid">

  <div class='toc margined overflow' id='toc'>
    <br>
    <br>
    <br>
      <div  ><br></div><h2 ><strong>TABLE OF CONTENTS</strong></h2><div  ><br></div>

      <router-link to="/privacy#top">
        PRIVACY POLICY
      </router-link>

      <router-link to="/privacy#infocollect">
        WHAT INFORMATION DO WE COLLECT?
      </router-link>

      <router-link to="/privacy#infouse">
        HOW DO WE PROCESS YOUR INFORMATION?
      </router-link>

      <router-link to="/privacy#legalbases">
        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
      </router-link>

      <router-link to="/privacy#whoshare">
      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </router-link>

      <router-link to="/privacy#cookies">
        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </router-link>

      <router-link to="/privacy#sociallogins">
        HOW DO WE HANDLE YOUR SOCIAL LOGINS?
      </router-link>

      <router-link to="/privacy#intltransfers">
        IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </router-link>

      <router-link to="/privacy#inforetain">
        HOW LONG DO WE KEEP YOUR INFORMATION?
      </router-link>

      <router-link to="/privacy#infosafe">
        HOW DO WE KEEP YOUR INFORMATION SAFE
      </router-link>

      <router-link to="/privacy#infominors">
        DO WE COLLECT INFORMATION FROM MINORS?
      </router-link>

      <router-link to="/privacy#privacyrights">
        WHAT ARE YOUR PRIVACY RIGHTS?
      </router-link>

      <router-link to="/privacy#DNT">
        CONTROLS FOR DO-NOT-TRACK FEATURES
      </router-link>

      <router-link to="/privacy#caresidents">
        DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </router-link>

      <router-link to="/privacy#policyupdates">
        DO WE MAKE UPDATES TO THIS NOTICE?
      </router-link>

      <router-link to="/privacy#contact">
        HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </router-link>

      <router-link to="/privacy#request">
        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </router-link>
      <br>
      <br>
      <br>

    </div>
    <div class="margined main">
      <br>
      <br>
      <br>
      <div id="top">
      <br>
      <br>
      <br>
      <div class="styles-documentPreviewer-881656"><div><span class="document-previewer-logo-d16635"></span><div class="document-previewer-wrapper-a717db">      <div data-custom-class="body">
      <div><strong><span style="font-size: 26px;"><span
data-custom-class="title"><bdt class="block-component"></bdt><bdt
class="question">PRIVACY POLICY</bdt><bdt
class="statement-end-if-in-editor"></bdt></span></span></strong></div><div><br></div><div><span
style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
data-custom-class="subtitle">Last updated <bdt class="question">April 20,
2023</bdt></span></span></strong></span></div><div><br></div><div><br></div><div><br></div><div
style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span
data-custom-class="body_text">This privacy notice for <bdt
class="question">Breathesafe LLC<bdt class="block-component"></bdt></bdt> (<bdt
class="block-component"></bdt>"<bdt
class="block-component"></bdt><strong>Company</strong><bdt
class="statement-end-if-in-editor"></bdt>," "<strong>we</strong>,"
"<strong>us</strong>," or "<strong>our</strong>"<bdt
class="statement-end-if-in-editor"></bdt></span><span
data-custom-class="body_text">), describes how and why we might collect, store,
use, and/or share (<bdt
class="block-component"></bdt>"<strong>process</strong>"<bdt
class="statement-end-if-in-editor"></bdt>) your information when you use our
services (<bdt class="block-component"></bdt>"<strong>Services</strong>"<bdt
class="statement-end-if-in-editor"></bdt>), such as when
you:</span></span></span><span style="font-size: 15px;"><span style="color:
rgb(127, 127, 127);"><span data-custom-class="body_text"><span style="color:
rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">Visit our website<bdt
class="block-component"></bdt> at <bdt class="question"><a
href="https://www.breathesafe.xyz" target="_blank"
data-custom-class="link">https://www.breathesafe.xyz</a></bdt><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor">, or
any website of ours that links to this privacy
notice</bdt></span></span></span></span></span></span></span></span></li></ul><div><bdt
class="block-component"><span style="font-size: 15px;"><span style="font-size:
15px;"><span style="color: rgb(127, 127, 127);"><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span></bdt><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color:
rgb(127, 127, 127);"><span data-custom-class="body_text"><span style="color:
rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">Engage with us in other related ways, including
any sales, marketing, or events<span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color:
rgb(127, 127, 127);"><span data-custom-class="body_text"><strong>Questions or
concerns?&nbsp;</strong>Reading this privacy notice will help you understand
your privacy rights and choices. If you do not agree with our policies and
practices, please do not use our Services. If you still have any questions or
concerns, please contact us at <bdt
class="question">info@breathesafe.xyz</bdt>.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><strong><span style="font-size:
15px;"><span data-custom-class="heading_1">SUMMARY OF KEY
POINTS</span></span></strong></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong><em>This summary provides
key points from our privacy notice, but you can find out more details about any
of these topics by clicking the link following each key point or by using
our&nbsp;</em></strong></span></span>

<router-link to='privacy#toc'>
<span style="font-size: 15px;"><span
data-custom-class="body_text"><strong><em>table of
contents</em></strong></span></span>
</router-link>

<span style="font-size: 15px;"><span
data-custom-class="body_text"><strong><em>&nbsp;below to find the section you
are looking for.</em></strong></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>What personal information do
we process?</strong> When you visit, use, or navigate our Services, we may
process personal information depending on how you interact with <bdt
class="block-component"></bdt><bdt class="question">Breathesafe LLC</bdt><bdt
class="statement-end-if-in-editor"></bdt> and the Services, the choices you
make, and the products and features you use. Learn more
about&nbsp;</span></span>

<router-link to='/privacy#personalinfo'>
<span style="font-size: 15px;"><span data-custom-class="body_text">personal
information you disclose to us</span></span>

</router-link>
<span
data-custom-class="body_text">.</span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>Do we process any sensitive
personal information?</strong> <bdt class="block-component"></bdt>
We may process sensitive personal information when necessary with your consent
or as otherwise permitted by applicable law. Learn more about the
<router-link to='/privacy#sensitiveinfo'>sensitive information we process</router-link>.
<bdt
class="else-block"></bdt></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>Do we receive any
information from third parties?</strong> <bdt class="block-component"></bdt>We
do not receive any information from third parties.<bdt
class="else-block"></bdt></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>How do we process your
information?</strong> We process your information to provide, improve, and
administer our Services, communicate with you, for security and fraud
prevention, and to comply with law. We may also process your information for
other purposes with your consent. We process your information only when we have
a valid legal reason to do so. Learn more about&nbsp;</span></span>

<router-link to='/privacy#infouse'>
<span style="font-size: 15px;"><span
data-custom-class="body_text">how we process your
information</span></span>
</router-link>

<span
data-custom-class="body_text">.</span></div><div style="line-height:
1.5;"><br></div>

<div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>In what situations and with
which <bdt class="block-component"></bdt>types of <bdt
class="statement-end-if-in-editor"></bdt>parties do we share personal
information?</strong> We may share information in specific situations and with
specific <bdt class="block-component"></bdt>categories of <bdt
class="statement-end-if-in-editor"></bdt>third parties. Learn more
about&nbsp;</span></span>

<router-link to='/privacy#whoshare'>
<span
style="font-size: 15px;"><span data-custom-class="body_text">when and with whom
we share your personal information</span></span>
</router-link>

<span style="font-size:
15px;"><span data-custom-class="body_text">.<bdt
class="block-component"></bdt></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>How do we keep your
information safe?</strong> We have <bdt
class="block-component"></bdt>organizational<bdt
class="statement-end-if-in-editor"></bdt> and technical processes and
procedures in place to protect your personal information. However, no
electronic transmission over the internet or information storage technology can
be guaranteed to be 100% secure, so we cannot promise or guarantee that
hackers, cybercriminals, or other <bdt
class="block-component"></bdt>unauthorized<bdt
class="statement-end-if-in-editor"></bdt> third parties will not be able to
defeat our security and improperly collect, access, steal, or modify your
information. Learn more about&nbsp;</span></span>
<router-link to="/privacy#infosafe">
<span style="font-size: 15px;"><span
data-custom-class="body_text">how we keep your information
safe</span></span>
</router-link>
<span data-custom-class="body_text">.</span><span
style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="body_text"><strong>What are
your rights?</strong> Depending on where you are located geographically, the
applicable privacy law may mean you have certain rights regarding your personal
information. Learn more about&nbsp;</span></span>


<router-link to="/privacy#privacyrights">
<span style="font-size: 15px;"><span
data-custom-class="body_text">your privacy rights</span></span>
</router-link>

<span
data-custom-class="body_text">.</span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>How do you exercise your
rights?</strong> The easiest way to exercise your rights is by <bdt
class="block-component">submitting a&nbsp;</bdt></span></span>

<a data-custom-class="link" href="https://app.termly.io/notify/da32d236-6da0-4eac-b420-b983a26320f4" rel="noopener noreferrer" target="_blank">
<span style="font-size: 15px;"><span
data-custom-class="body_text">data subject access
request</span></span>
</a>

<span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>, or by
contacting us. We will consider and act upon any request in accordance with
applicable data protection laws.</span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text">Want to learn more about what <bdt
class="block-component"></bdt><bdt class="question">Breathesafe LLC</bdt><bdt
class="statement-end-if-in-editor"></bdt> does with any information we
collect?&nbsp;</span></span>Read the privacy policy below in full<span style="font-size: 15px;"><span
data-custom-class="body_text">.</span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><br></div>

<div id="infocollect" style="line-height:
1.5;" >
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">1.
WHAT INFORMATION DO WE
COLLECT?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="personalinfo" style="line-height:
1.5;">
<br>
<br>
<br>
<span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
style="font-size: 15px;"><strong>Personal information you disclose to
us</strong></span></span></div><div><div><br></div><div style="line-height:
1.5;"><span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span data-custom-class="body_text"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:</em></strong></span></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect
personal information that you provide to
us.</em></span></span></span></span></span></span></div></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect
personal information that you voluntarily provide to us when you <span
style="font-size: 15px;"><bdt class="block-component"></bdt></span>register on
the Services,&nbsp;</span><span style="font-size: 15px;"><span
data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="statement-end-if-in-editor"></bdt></span></span><span
data-custom-class="body_text">express an interest in obtaining information
about us or our products and Services, when you participate in activities on
the Services, or otherwise when you contact
us.</span></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong>Personal Information Provided by
You.</strong> The personal information that we collect depends on the context
of your interactions with us and the Services, the choices you make, and the
products and features you use. The personal information we collect may include
the following:<span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="question">names</bdt></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="question">email
addresses</bdt></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="question">usernames</bdt></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="question">passwords</bdt></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="question">co2 monitor make &amp;
model</bdt></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></span></span></span></div>
  <ul>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <bdt class="question">mask/respirator make and model</bdt>
              </span>
            </span>
          </span>
        </span>
      </span>
    </li>
  </ul>
  <ul>
    <li style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <bdt class="question">contact preferences</bdt>
              </span>
            </span>
          </span>
        </span>
      </span>
    </li>
  </ul>
<div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="forloop-component"></bdt></span><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></div><div
id="sensitiveinfo" style="line-height: 1.5;">

<br>
<br>
<br>
<span style="font-size:
15px;"><span data-custom-class="body_text"><strong>Sensitive
Information.</strong>

When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:

<ul>
  <li>name</li>
  <li>email</li>
  <li>password</li>
  <li>location (for contributing venue data and finding safer venues via geolocation).</li>
  <li>facial measurements (for assessing respirator fit.)</li>
  <li>facial hair length (for assessing respirator fit.)</li>
  <li>respirator comfort (for assessing adequacy of a respirator to an individual.)</li>
  <li>user seal check results (for assessing respirator fit.)</li>
  <li>demographic information (for assessing sampling bias and assessing equity concerns related to masks.) </li>
</ul>

</span></span>
</div>
<div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text"><bdt
class="else-block"></bdt></span></span><span style="font-size: 15px; color:
rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="block-component"><bdt
class="block-component"></bdt></bdt></span></span></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong>Social Media Login Data.&nbsp;</strong>We
may provide you with the option to register with us using your existing social
media account details, like your Facebook, Twitter, or other social media
account. If you choose to register in this way, we will collect the information
described in the section called <bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt><span style="font-size: 15px;"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);">

<router-link to='privacy#sociallogins'>
HOW DO WE HANDLE YOUR SOCIAL
LOGINS?
</router-link>
</span></span></span></span><bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt> below.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt class="statement-end-if-in-editor"><bdt
class="statement-end-if-in-editor"></bdt></bdt></span></span></span></span><bdt
class="block-component"></bdt></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">All personal
information that you provide to us must be true, complete, and accurate, and
you must notify us of any changes to such personal
information.</span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><span data-custom-class="heading_2" style="color:
rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Information automatically
collected</strong></span></span></div><div><div><br></div><div
style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span
data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong><em>In
Short:</em></strong></span></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some
information — such as your Internet Protocol (IP) address and/or browser and
device characteristics — is collected automatically when you visit our
Services.</em></span></span></span></span></span></span></div></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We automatically
collect certain information when you visit, use, or navigate the Services. This
information does not reveal your specific identity (like your name or contact
information) but may include device and usage information, such as your IP
address, browser and device characteristics, operating system, language
preferences, referring URLs, device name, country, location, information about
how and when you use our Services, and other technical information. This
information is primarily needed to maintain the security and operation of our
Services, and for our internal analytics and reporting
purposes.</span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">Like many businesses, we also collect information
through cookies and similar technologies. <bdt
class="block-component"></bdt><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"><span
data-custom-class="body_text"></span></bdt></span><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">The information we collect includes:<bdt
class="block-component"></bdt></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data
is service-related, diagnostic, usage, and performance information our servers
automatically collect when you access or use our Services and which we record
in log files. Depending on how you interact with us, this log data may include
your IP address, device information, browser type, and settings and information
about your activity in the Services<span style="font-size:
15px;">&nbsp;</span>(such as the date/time stamps associated with your usage,
pages and files viewed, searches, and other actions you take such as which
features you use), device event information (such as system activity, error
reports (sometimes called <bdt class="block-component"></bdt>"crash dumps"<bdt
class="statement-end-if-in-editor"></bdt>), and hardware settings).<span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><em>Location Data.</em> We collect location data
such as information about your device's location, which can be either precise
or imprecise. How much information we collect depends on the type and settings
of the device you use to access the Services. For example, we may use GPS and
other technologies to collect geolocation data that tells us your current
location (based on your IP address). You can opt out of allowing us to collect
this information either by refusing access to the information or by disabling
your Location setting on your device. However, if you choose to opt out, you
may not be able to use certain aspects of the Services.<span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li></ul><div><bdt
class="block-component"><span style="font-size: 15px;"></span></bdt><bdt
class="statement-end-if-in-editor"></bdt><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size:
15px;"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);
font-size: 15px;"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"><bdt
class="block-component"></bdt></bdt></span></span></span></span></span><span
style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></div>

<div id="infouse"
style="line-height: 1.5;">

<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span id="control" style="color: rgb(0, 0, 0);"><strong><span
data-custom-class="heading_1">HOW DO WE PROCESS YOUR
INFORMATION?</span></strong></span></span></span></span></span></div><div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);
font-size: 15px;"><span data-custom-class="body_text"><span style="font-size:
15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>We process your information to provide, improve,
and administer our Services, communicate with you, for security and fraud
prevention, and to comply with law. We may also process your information for
other purposes with your
consent.</em></span></span></span></span></span></span></div></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>We process
your personal information for a variety of reasons, depending on how you
interact with our Services, including:</strong><bdt
class="block-component"></bdt></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong>To facilitate account creation and
authentication and otherwise manage user accounts.&nbsp;</strong>We may process
your information so you can create and log in to your account, as well as keep
your account in working order.<span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><div style="line-height:
1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong>To deliver and facilitate delivery of
services to the user.&nbsp;</strong>We may process your information to provide
you with the requested service.<span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><div style="line-height:
1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size:
15px;"></span></bdt><div style="line-height: 1.5;"><bdt
class="block-component"><span style="font-size: 15px;"></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"></span></bdt><div style="line-height: 1.5;"><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><p style="font-size: 15px;
line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"></span></bdt></p><p style="font-size: 15px; line-height: 1.5;"><bdt
class="block-component"><span style="font-size: 15px;"></span></bdt></p><p
style="font-size: 15px; line-height: 1.5;"><bdt
class="block-component"></bdt></p><p style="font-size: 15px; line-height:
1.5;"><bdt class="block-component"></bdt></p><ul><li style="line-height:
1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89,
89);"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong>To request feedback.&nbsp;</strong>We may
process your information when necessary to request feedback and to contact you
about your use of our Services.<span style="color: rgb(89, 89, 89);"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"></span></bdt><div style="line-height: 1.5;"><bdt
class="block-component"><span style="font-size:
15px;"></span></bdt></div><ul><li style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To send
you marketing and promotional communications.&nbsp;</strong>We may process the
personal information you send to us for our marketing purposes, if this is in
accordance with your marketing preferences. You can opt out of our marketing
emails at any time. For more information, see <bdt
class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt></span></span></span><a
data-custom-class="link" href="#privacyrights"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">WHAT ARE YOUR PRIVACY

RIGHTS?</span></span></span></a><span style="font-size: 15px; color: rgb(89,
89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt> below).</span><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"></span></bdt><div style="line-height: 1.5;"><span style="font-size:
15px;"><bdt class="block-component"><span
data-custom-class="body_text"></span></bdt></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><strong>To deliver targeted advertising to
you.</strong> We may process your information to develop and display <bdt
class="block-component"></bdt>personalized<bdt
class="statement-end-if-in-editor"></bdt> content and advertising tailored to
your interests, location, and more.<bdt
class="block-component"></bdt></span><bdt class="block-component"><span
data-custom-class="body_text"></span></bdt></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>To protect our Services.</strong> We may
process your information as part of our efforts to keep our Services safe and
secure, including fraud monitoring and prevention.</span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>To identify usage trends.</strong> We may
process information about how you use our Services to better understand how
they are being used so we can improve them.</span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><strong>To determine the effectiveness of our
marketing and promotional campaigns.</strong> We may process your information
to better understand how to provide marketing and promotional campaigns that
are most relevant to you.<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>To save or protect an individual's vital
interest.</strong> We may process your information when necessary to save or
protect an individual’s vital interest, such as to prevent
harm.</span></span><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><span style="font-size:
15px;"><span data-custom-class="body_text"><bdt
class="forloop-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="question"><strong>Public Interest</strong></bdt><strong>.</strong> <bdt
class="question">To educate other users about estimated risk of particular
venues</bdt></span></span></li></ul><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="forloop-component"></bdt><bdt
class="statement-end-if-in-editor"></bdt></span></span><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><div
style="line-height: 1.5;"><br></div>

<div id="legalbases" style="line-height: 1.5;">
<br>
<br>
<br>
<strong><span style="font-size: 15px;"><span
data-custom-class="heading_1">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
INFORMATION?</span></span></strong></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><em><span style="font-size:
15px;"><span data-custom-class="body_text"><strong>In Short:&nbsp;</strong>We
only process your personal information when we believe it is necessary and we
have a valid legal reason (i.e.<bdt class="block-component"></bdt>,<bdt
class="statement-end-if-in-editor"></bdt> legal basis) to do so under
applicable law, like with your consent, to comply with laws, to provide you
with services to enter into or <bdt class="block-component"></bdt>fulfill<bdt
class="statement-end-if-in-editor"></bdt> our contractual obligations, to
protect your rights, or to <bdt class="block-component"></bdt>fulfill<bdt
class="statement-end-if-in-editor"></bdt> our legitimate business
interests.</span></span></em><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="block-component"></bdt></span><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><em><span style="font-size:
15px;"><span data-custom-class="body_text"><strong><u>If you are located in the
EU or UK, this section applies to you.</u></strong></span></span></em><span
style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="body_text">The General Data
Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
bases we rely on in order to process your personal information. As such, we may
rely on the following legal bases to process your personal
information:</span></span></div><ul><li style="line-height: 1.5;"><span
style="font-size: 15px;"><span
data-custom-class="body_text"><strong>Consent.&nbsp;</strong>We may process
your information if you have given us permission (i.e.<bdt
class="block-component"></bdt>,<bdt class="statement-end-if-in-editor"></bdt>
consent) to use your personal information for a specific purpose. You can
withdraw your consent at any time. Learn more about&nbsp;</span></span>

<router-link to="privacy#withdrawconsent"><span style="font-size:
15px;"><span data-custom-class="body_text">withdrawing your
consent</span></span>
</router-link>
<span
data-custom-class="body_text">.</span></li></ul><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>Performance of a Contract.</strong> We may
process your personal information when we believe it is necessary to <bdt
class="block-component"></bdt>fulfill<bdt
class="statement-end-if-in-editor"></bdt> our contractual obligations to you,
including providing our Services or at your request prior to entering into a
contract with you.</span></span><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>Legitimate Interests.</strong> We may process
your information when we believe it is reasonably necessary to achieve our
legitimate business interests and those interests do not outweigh your
interests and fundamental rights and freedoms. For example, we may process your
personal information for some of the purposes described in order
to:</span></span></li></ul><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">Send users information about special offers and
discounts on our products and services<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">Develop and display <bdt
class="block-component"></bdt>personalized<bdt
class="statement-end-if-in-editor"></bdt> and relevant advertising content for
our users<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt class="block-component"></bdt>Analyze<bdt
class="statement-end-if-in-editor"></bdt> how our Services are used so we can
improve them to engage and retain users<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">Support our marketing activities<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">Diagnose problems and/or prevent fraudulent
activities<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul style="margin-left:
40px;"><li style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">Understand how our users use our products and services
so we can improve user experience<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt class="block-component"></bdt></span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>Legal Obligations.</strong> We may process
your information where we believe it is necessary for compliance with our legal
obligations, such as to cooperate with a law enforcement body or regulatory
agency, exercise or defend our legal rights, or disclose your information as
evidence in litigation in which we are involved.<bdt
class="statement-end-if-in-editor"></bdt><br></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><strong>Vital Interests.</strong> We may process your
information where we believe it is necessary to protect your vital interests or
the vital interests of a third party, such as situations involving potential
threats to the safety of any person.</span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><span
data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;"><strong><u><em>If
you are located in Canada, this section applies to you.</em></u></strong><bdt
class="statement-end-if-in-editor"></bdt></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;">We may process
your information if you have given us specific permission (i.e.<bdt
class="block-component"></bdt>,<bdt class="statement-end-if-in-editor"></bdt>
express consent) to use your personal information for a specific purpose, or in
situations where your permission can be inferred (i.e.<bdt
class="block-component"></bdt>,<bdt class="statement-end-if-in-editor"></bdt>
implied consent). You can&nbsp;</span></span>

<router-link to="privacy#withdrawconsent"><span data-custom-class="body_text"><span
style="font-size: 15px;">withdraw your consent</span></span>
</router-link>

<span
data-custom-class="body_text"><span style="font-size: 15px;">&nbsp;at any
time.</span></span></div><div style="line-height: 1.5;"><br></div><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">In some exceptional cases, we may be legally permitted
under applicable law to process your information without your consent,
including, for example:</span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;">If
collection is clearly in the interests of an individual and consent cannot be
obtained in a timely way</span></span></li></ul><div style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;">For
investigations and fraud detection and prevention<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">For business transactions provided certain conditions
are met</span></span><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">If it is contained in a witness statement and the
collection is necessary to assess, process, or settle an insurance
claim</span></span><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;">For identifying injured, ill, or deceased persons and
communicating with next of kin</span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;">If we
have reasonable grounds to believe an individual has been, is, or may be victim
of financial abuse<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;">If it
is reasonable to expect collection and use with consent would compromise the
availability or the accuracy of the information and the collection is
reasonable for purposes related to investigating a breach of an agreement or a
contravention of the laws of Canada or a province<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span data-custom-class="body_text"><span
style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;">If
disclosure is required to comply with a subpoena, warrant, court order, or
rules of the court relating to the production of records<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><bdt class="block-component"><span style="font-size:
15px;"><span data-custom-class="body_text"></span></span></bdt></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text">If it was produced by an individual in the course
of their employment, business, or profession and the collection is consistent
with the purposes for which the information was produced<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">If the
collection is solely for journalistic, artistic, or literary purposes<bdt
class="statement-end-if-in-editor"></bdt></span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">If the
information is publicly available and is specified by the
regulations</span><bdt class="statement-end-if-in-editor"><span
data-custom-class="body_text"></span></bdt></span></li></ul><div
style="line-height: 1.5;"><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><div
style="line-height: 1.5;"><br></div>

<div id="whoshare" style="line-height:
1.5;">

<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
INFORMATION?</span></strong></span></span></span></span></span></div>
<div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:</em></strong><em>&nbsp;We may share information in specific situations
described in this section and/or with the following <bdt
class="block-component"></bdt>categories of <bdt
class="statement-end-if-in-editor"></bdt>third
parties.</em></span></span></span></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="body_text"><strong>Vendors,
Consultants, and Other Third-Party Service Providers.</strong> We may share
your data with third-party vendors, service providers, contractors, or agents
(<bdt class="block-component"></bdt>"<strong>third parties</strong>"<bdt
class="statement-end-if-in-editor"></bdt>) who perform services for us or on
our behalf and require access to such information to do that work. <bdt
class="block-component"></bdt>We have contracts in place with our third
parties, which are designed to help safeguard your personal information. This
means that they cannot do anything with your personal information unless we
have instructed them to do it. They will also not share your personal
information with any <bdt class="block-component"></bdt>organization<bdt
class="statement-end-if-in-editor"></bdt> apart from us. They also commit to
protect the data they hold on our behalf and to retain it for the period we
instruct. <bdt class="statement-end-if-in-editor"></bdt>The <bdt
class="block-component"></bdt>categories of <bdt
class="statement-end-if-in-editor"></bdt>third parties we may share personal
information with are as follows:</span></span><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><span
data-custom-class="body_text"><bdt class="block-component"></bdt></span><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><span
data-custom-class="body_text"></span><span
data-custom-class="body_text"></span><span
data-custom-class="body_text"></span><span
data-custom-class="body_text"></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size:
15px;"><span data-custom-class="body_text">We <bdt
class="block-component"></bdt>also <bdt
class="statement-end-if-in-editor"></bdt>may need to share your personal
information in the following situations:</span></span></div><ul><li
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><strong>Business Transfers.</strong> We may share
or transfer your information in connection with, or during negotiations of, any
merger, sale of company assets, financing, or acquisition of all or a portion
of our business to another company.</span></span></li></ul><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><strong>When we use Google Maps Platform
APIs.</strong> We may share your information with certain Google Maps Platform
APIs (e.g.<bdt class="block-component"></bdt>,<bdt
class="statement-end-if-in-editor"></bdt> Google Maps API, Places
API).</span></span><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="block-component"></bdt><bdt
class="block-component"></bdt> We obtain and store on your device (<bdt
class="block-component"></bdt>"cache"<bdt
class="statement-end-if-in-editor"></bdt>) your location<bdt
class="block-component"></bdt> for <bdt class="question">one (1)</bdt>
months<bdt class="statement-end-if-in-editor"></bdt>. You may revoke your
consent anytime by contacting us at the contact details provided at the end of
this document.<bdt class="statement-end-if-in-editor"></bdt><bdt
class="block-component"></bdt></span></span></li></ul><div style="line-height:
1.5;"><span style="font-size: 15px;"><bdt class="block-component"><span
data-custom-class="body_text"></span></bdt></span><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><div style="line-height:
1.5;"><bdt class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><span style="color: rgb(89,
89, 89); font-size: 15px;"><span style="font-size: 15px;"><span style="color:
rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89,
89, 89);"><bdt class="block-component"><span
data-custom-class="heading_1"></span></bdt></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>
<div id="cookies" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">DO
WE USE COOKIES AND OTHER TRACKING
TECHNOLOGIES?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:</em></strong><em>&nbsp;We may use cookies and other tracking
technologies to collect and store your
information.</em></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">We may use cookies and similar
tracking technologies (like web beacons and pixels) to access or store
information. Specific information about how we use such technologies and how
you can refuse certain cookies is set out in our Cookie Notice<span
style="color: rgb(89, 89, 89); font-size: 15px;"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>.</span><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size:
15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="sociallogins" style="line-height: 1.5;">
<br>
<br>
<br>

<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">HOW
DO WE HANDLE YOUR SOCIAL
LOGINS?</span></strong></span></span></span></span></span></div>

<div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>If you choose to register or log in to our
Services using a social media account, we may have access to certain
information about you.</em></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">Our Services offer you the ability to
register and log in using your third-party social media account details (like
your Facebook or Twitter logins). Where you choose to do this, we will receive
certain profile information about you from your social media provider. The
profile information we receive may vary depending on the social media provider
concerned, but will often include your name, email address, friends list, and
profile picture, as well as other information you choose to make public on such
a social media platform. <span style="font-size: 15px;"><span style="color:
rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will use the
information we receive only for the purposes that are described in this privacy
notice or that are otherwise made clear to you on the relevant Services. Please
note that we do not control, and are not responsible for, other uses of your
personal information by your third-party social media provider. We recommend
that you review their privacy notice to understand how they collect, use, and
share your personal information, and how you can set your privacy preferences
on their sites and apps.<span style="color: rgb(89, 89, 89); font-size:
15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="color: rgb(89,
89, 89); font-size: 15px;"><span style="font-size: 15px;"><span style="color:
rgb(89, 89, 89);"><span style="font-size: 15px;"><span style="color: rgb(89,
89, 89);"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span><bdt
class="block-component"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></bdt></span></span></span></span></span></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="intltransfers" style="line-height: 1.5;">
<br>
<br>
<br>

<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>We may transfer, store, and process your
information in countries other than your
own.</em></span></span></span></div><div style="line-height:
1.5;"><br></div><div data-custom-class="body_text" style="line-height:
1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">Our servers are located in<span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="forloop-component"></bdt><bdt
class="block-component"></bdt><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span> the
<span style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size:
15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="question">United
States</bdt></span></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span><bdt
class="block-component"></bdt></span></span></span></span></span></span><bdt
class="forloop-component"></bdt><span style="font-size: 15px; color: rgb(89,
89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">. If you are accessing our Services from
outside</span><span data-custom-class="body_text"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="forloop-component"></bdt><bdt
class="block-component"></bdt><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span> the
<span style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size:
15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="question">United
States</bdt></span></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span><bdt
class="block-component"></bdt></span></span></span></span></span></span><bdt
class="forloop-component"></bdt><span data-custom-class="body_text">, please be
aware that your information may be transferred to, stored, and processed by us
in our facilities and by those third parties with whom we may share your
personal information (see <bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt></span>

<router-link to='/privacy#whoshare'>
<span
style="font-size: 15px;"><span data-custom-class="body_text">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
INFORMATION?</span></span>
</router-link>

<span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt> above),
in</span></span>&nbsp;</span><span style="font-size: 15px;"><span style="color:
rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="forloop-component"></bdt><span style="font-size: 15px; color: rgb(89,
89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span> the
<span style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size:
15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="question">United
States,</bdt></span></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="else-block"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span><bdt
class="forloop-component"></bdt></span></span></span><span style="font-size:
15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">&nbsp;and other
countries.</span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">If you are a resident in the European
Economic Area (EEA) or United Kingdom (UK), then these countries may not
necessarily have data protection laws or other similar laws as comprehensive as
those in your country. However, we will take all necessary measures to protect
your personal information in accordance with this privacy notice and applicable
law.<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">European
Commission's Standard Contractual Clauses:</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We have
implemented measures to protect your personal information, including by using
the European Commission's Standard Contractual Clauses for transfers of
personal information between our group companies and between us and our
third-party providers. These clauses require all recipients to protect all
personal information that they process originating from the EEA or UK in
accordance with European data protection laws and regulations.<span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span>&nbsp;</span>Our Standard
Contractual Clauses can be provided upon request.<span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span>&nbsp;</span>We have
implemented similar appropriate safeguards with our third-party service
providers and partners and further details can be provided upon request.<span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="color: rgb(89,
89, 89); font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size:
15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="inforetain" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">HOW
LONG DO WE KEEP YOUR
INFORMATION?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>We keep your information for as long as necessary
to <bdt class="block-component"></bdt>fulfill<bdt
class="statement-end-if-in-editor"></bdt> the purposes outlined in this privacy
notice unless otherwise required by law.</em></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will only keep
your personal information for as long as it is necessary for the purposes set
out in this privacy notice, unless a longer retention period is required or
permitted by law (such as tax, accounting, or other legal requirements).<bdt
class="block-component"></bdt> No purpose in this notice will require us
keeping your personal information for longer than <span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span><bdt
class="block-component"></bdt>the period of time in which users have an account
with us<bdt class="block-component"></bdt><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="else-block"></bdt></span></span></span>.</span></span></span></div><div
style="line-height: 1.5;"><br></div>
<div style="line-height: 1.5;">
  <span style="font-size: 15px; color: rgb(89, 89, 89);">
    <span style="font-size: 15px;
  color: rgb(89, 89, 89);">
      <span data-custom-class="body_text">When we have no
  ongoing legitimate business need to process your personal information, we will
  either delete or <bdt class="block-component"></bdt>anonymize<bdt
  class="statement-end-if-in-editor"></bdt> such information, or, if this is not
  possible (for example, because your personal information has been stored in
  backup archives), then we will securely store your personal information and
  isolate it from any further processing until deletion is possible.


      </span>
    </span>
  </span>
</div>
<div style="line-height: 1.5;"><br></div>

<div id="infosafe" style="line-height:
1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">HOW
DO WE KEEP YOUR INFORMATION
SAFE?</span></strong></span></span></span></span></span>
</div>

<div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>We aim to protect your personal information
through a system of <bdt class="block-component"></bdt>organizational<bdt
class="statement-end-if-in-editor"></bdt> and technical security
measures.</em></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">We have implemented appropriate and
reasonable technical and <bdt class="block-component"></bdt>organizational<bdt
class="statement-end-if-in-editor"></bdt> security measures designed to protect
the security of any personal information we process. However, despite our
safeguards and efforts to secure your information, no electronic transmission
over the Internet or information storage technology can be guaranteed to be
100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
other <bdt class="block-component"></bdt>unauthorized<bdt
class="statement-end-if-in-editor"></bdt> third parties will not be able to
defeat our security and improperly collect, access, steal, or modify your
information. Although we will do our best to protect your personal information,
transmission of personal information to and from our Services is at your own
risk. You should only access the Services within a secure environment.<span
style="color: rgb(89, 89, 89);"><bdt
class="statement-end-if-in-editor"></bdt></span><span style="color: rgb(89, 89,
89); font-size: 15px;"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="infominors" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">DO WE COLLECT INFORMATION FROM
MINORS?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market
to <bdt class="block-component"></bdt>children under 18 years of age<bdt
class="else-block"></bdt>.</em><bdt
class="block-component"></bdt></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We do not
knowingly solicit data from or market to children under 18 years of age. By
using the Services, you represent that you are at least 18 or that you are the
parent or guardian of such a minor and consent to such minor dependent’s use of
the Services. If we learn that personal information from users less than 18
years of age has been collected, we will deactivate the account and take
reasonable measures to promptly delete such data from our records. If you
become aware of any data we may have collected from children under age 18,
please contact us at <span style="color: rgb(89, 89, 89); font-size:
15px;"><span data-custom-class="body_text"><bdt
class="block-component"></bdt><bdt
class="question">

<a href="mailto:info@breathesafe.xyz">info@breathesafe.xyz</a>
  </bdt><bdt
class="else-block"></bdt></span></span>.</span><span
data-custom-class="body_text"><bdt
class="else-block"></bdt></span></span></span></div><div style="line-height:
1.5;"><br></div>

<div id="privacyrights" style="line-height: 1.5;">
<br>
<br>
<br>
<span
style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);
font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
WHAT ARE YOUR PRIVACY
RIGHTS?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:</em></strong><em>&nbsp;<span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><span data-custom-class="body_text"><em><bdt
class="block-component"></bdt></em></span></span></span>In some regions, such
as <bdt class="block-component"></bdt>the European Economic Area (EEA), United
Kingdom (UK), and Canada<bdt class="block-component"></bdt>, you have rights
that allow you greater access to and control over your personal
information.<span style="color: rgb(89, 89, 89);"><span style="font-size:
15px;"><span data-custom-class="body_text"><em><bdt
class="statement-end-if-in-editor"></bdt></em></span></span>&nbsp;</span>You
may review, change, or terminate your account at any time.</em><span
style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt
class="block-component"></bdt></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">In some regions
(like <bdt class="block-component"></bdt>the EEA, UK, and Canada<bdt
class="block-component"></bdt>), you have certain rights under applicable data
protection laws. These may include the right (i) to request access and obtain a
copy of your personal information, (ii) to request rectification or erasure;
(iii) to restrict the processing of your personal information; and (iv) if
applicable, to data portability. In certain circumstances, you may also have
the right to object to the processing of your personal information. You can
make such a request by contacting us by using the contact details provided in
the section <bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt></span></span></span>
<a data-custom-class="link" href="/privacy#contact"><span style="font-size: 15px; color:
rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS
NOTICE?</span></span></span></a><span style="font-size: 15px; color: rgb(89,
89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt> below.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will consider
and act upon any request in accordance with applicable data protection
laws.</span><span data-custom-class="body_text"><span style="font-size:
15px;"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);">&nbsp;</span></div><div style="line-height: 1.5;"><span style="font-size:
15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">If you are located in the EEA or UK
and you believe we are unlawfully processing your personal information, you
also have the right to complain to your <span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><a
data-custom-class="link"
href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
rel="noopener noreferrer" target="_blank"><span style="font-size: 15px;">Member
State data protection authority</span></a></span></span></span></span></span>
or&nbsp;</span></span></span><a
href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
rel="noopener noreferrer" target="_blank"><span data-custom-class="link"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">UK data protection
authority</span></span></span></span></a><span style="font-size: 15px; color:
rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are located
in Switzerland, you may contact the <span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><a data-custom-class="link"
href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer"
target="_blank">Federal Data Protection and Information
Commissioner</a></span></span></span></span></span></span>.</span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div id="withdrawconsent">
<br>
<br>
<br>
</div>
<div
style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong>
If we are relying on your consent to process your personal information,<bdt
class="block-component"></bdt> which may be express and/or implied consent
depending on the applicable law,<bdt class="statement-end-if-in-editor"></bdt>
you have the right to withdraw your consent at any time. You can withdraw your
consent at any time by contacting us by using the contact details provided in
the section <bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt></span></span></span>

<router-link to='/privacy#contact'>
<span style="font-size: 15px; color:
rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS
NOTICE?</span></span></span>

</router-link>
<span style="font-size: 15px; color: rgb(89,
89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt
class="statement-end-if-in-editor"></bdt> below<bdt
class="block-component"></bdt>.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="body_text">However, please
note that this will not affect the lawfulness of the processing before its
withdrawal nor,<bdt class="block-component"></bdt> when applicable law
allows,<bdt class="statement-end-if-in-editor"></bdt> will it affect the
processing of your personal information conducted in reliance on lawful
processing grounds other than consent.<bdt
class="block-component"></bdt></span></span><bdt class="block-component"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px;"><span data-custom-class="heading_2"><strong>Account
Information</strong></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;">If you would at
any time like to review or change the information in your account or terminate
your account, you can:<bdt
class="forloop-component"></bdt></span></span></div><ul><li style="line-height:
1.5;"><span data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="question">Log in to your account settings and update your user
account.</bdt></span></span></li></ul><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;"><bdt
class="forloop-component"></bdt></span></span></div><div style="line-height:
1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Upon
your request to terminate your account, we will deactivate or delete your
account and information from our active databases. However, we may retain some
information in our files to prevent fraud, troubleshoot problems, assist with
any investigations, enforce our legal terms and/or comply with applicable legal
requirements.</span></span><bdt class="statement-end-if-in-editor"><span
style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
data-custom-class="body_text"><span style="font-size: 15px;">If you have
questions or comments about your privacy rights, you may email us at <bdt
class="question">info@breathesafe.xyz</bdt>.</span></span><bdt
class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt></div><div
style="line-height: 1.5;"><br></div>

<div id="DNT" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
CONTROLS FOR DO-NOT-TRACK FEATURES

</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">Most web browsers
and some mobile operating systems and mobile applications include a
Do-Not-Track (<bdt class="block-component"></bdt>"DNT"<bdt
class="statement-end-if-in-editor"></bdt>) feature or setting you can activate
to signal your privacy preference not to have data about your online browsing
activities monitored and collected. At this stage no uniform technology
standard for <bdt class="block-component"></bdt>recognizing<bdt
class="statement-end-if-in-editor"></bdt> and implementing DNT signals has been
<bdt class="block-component"></bdt>finalized<bdt
class="statement-end-if-in-editor"></bdt>. As such, we do not currently respond
to DNT browser signals or any other mechanism that automatically communicates
your choice not to be tracked online. If a standard for online tracking is
adopted that we must follow in the future, we will inform you about that
practice in a revised version of this privacy
notice.</span></span></span></div><div style="line-height: 1.5;"><br></div>

<div id="caresidents" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127,
127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span id="control" style="color: rgb(0, 0,
0);"><strong><span data-custom-class="heading_1">DO CALIFORNIA RESIDENTS
HAVE SPECIFIC PRIVACY
RIGHTS?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
Short:&nbsp;</em></strong><em>Yes, if you are a resident of California, you are
granted specific rights regarding access to your personal
information.</em></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">California Civil Code Section
1798.83, also known as the <bdt class="block-component"></bdt>"Shine The
Light"<bdt class="statement-end-if-in-editor"></bdt> law, permits our users who
are California residents to request and obtain from us, once a year and free of
charge, information about categories of personal information (if any) we
disclosed to third parties for direct marketing purposes and the names and
addresses of all third parties with which we shared personal information in the
immediately preceding calendar year. If you are a California resident and would
like to make such a request, please submit your request in writing to us using
the contact information provided below.</span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are under
18 years of age, reside in California, and have a registered account with
Services, you have the right to request removal of unwanted data that you
publicly post on the Services. To request removal of such data, please contact
us using the contact information provided below and include the email address
associated with your account and a statement that you reside in California. We
will make sure the data is not publicly displayed on the Services, but please
be aware that the data may not be completely or comprehensively removed from
all our systems (e.g.<bdt class="block-component"></bdt>,<bdt
class="statement-end-if-in-editor"></bdt> backups, etc.).<span style="color:
rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span><bdt
class="block-component"><span style="font-size: 15px;"></span></bdt></div><div
style="line-height: 1.5;"><br></div>

<div id="policyupdates" style="line-height: 1.5;">
<br>
<br>
<br>
<span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">DO
WE MAKE UPDATES TO THIS
NOTICE?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em><strong>In
Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay
compliant with relevant laws.</em></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">We may update this
privacy notice from time to time. The updated version will be indicated by an
updated <bdt class="block-component"></bdt>"Revised"<bdt
class="statement-end-if-in-editor"></bdt> date and the updated version will be
effective as soon as it is accessible. If we make material changes to this
privacy notice, we may notify you either by prominently posting a notice of
such changes or by directly sending you a notification. We encourage you to
review this privacy notice frequently to be informed of how we are protecting
your information.</span></span></span></div><div style="line-height:
1.5;"><br></div><div id="contact" style="line-height: 1.5;"><span style="color:
rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size:
15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
HOW CAN YOU CONTACT US ABOUT THIS
NOTICE?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you have
questions or comments about this notice, you may <span style="color: rgb(89,
89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
class="block-component"></bdt>email us at <bdt
class="question">info@breathesafe.xyz</bdt><bdt
class="statement-end-if-in-editor"></bdt></span></span><span style="font-size:
15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text">&nbsp;or by post
to:</span></span></span></span></span></span></div><div style="line-height:
1.5;"><br></div><div style="line-height: 1.5;"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span data-custom-class="body_text"><span style="font-size: 15px;"><span
style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt class="question">Breathesafe
LLC</bdt></span></span></span></span></span><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
data-custom-class="body_text"><bdt
class="block-component"></bdt></span></span></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="question">138 Miller Ave</bdt><span style="color: rgb(89, 89, 89);"><span style="font-size:
15px;"><bdt
class="block-component"></bdt></span></span></span></span></div><div
style="line-height: 1.5;"><span style="font-size: 15px;"><span
data-custom-class="body_text"><bdt class="question">Rumford</bdt><span
style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt
class="block-component"></bdt><bdt class="block-component"></bdt>, <bdt
class="question">RI</bdt><bdt class="statement-end-if-in-editor"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt> <bdt
class="question">02916</bdt><bdt class="statement-end-if-in-editor"></bdt><bdt
class="block-component"></bdt><bdt class="block-component"></bdt><bdt
class="block-component"></bdt></span></span></span></span></div><div
style="line-height: 1.5;"><span data-custom-class="body_text" style="font-size:
15px;"><bdt class="question">United States<span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><span data-custom-class="body_text"><span
style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></bdt></span><bdt
class="block-component"><span style="font-size: 15px;"><span
data-custom-class="body_text"></span></span></bdt><bdt
class="statement-end-if-in-editor"></bdt><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><bdt
class="statement-end-if-in-editor"></bdt></span></span></span><span
data-custom-class="body_text"><span style="font-size: 15px;"><span
data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
class="statement-end-if-in-editor"><span style="color: rgb(89, 89, 89);"><span
style="font-size: 15px;"><span data-custom-class="body_text"><bdt
class="block-component"><bdt
class="block-component"></bdt></bdt></span></span></span></bdt></span></span></span><span
style="font-size: 15px;"><span data-custom-class="body_text"><span
style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size:
15px;"><span data-custom-class="body_text"><bdt
class="statement-end-if-in-editor"><bdt
class="block-component"></bdt></bdt></span></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div><div id="request" style="line-height:
1.5;"><span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89,
89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89,
89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
YOU?</span></strong></span></span></span></span></span></div><div
style="line-height: 1.5;"><br></div>

<div style="line-height: 1.5;"><span
style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px;
color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
class="block-component"></bdt>Based on the applicable laws of your country, you
may have the right to request access to the personal information we collect
from you, change that information, or delete it. <bdt
class="else-block"></bdt>To request to review, update, or delete your personal
information, please <bdt class="block-component"></bdt>fill out and submit
a&nbsp;</span><span style="color: rgb(48, 48, 241);"><span
data-custom-class="body_text"><span style="font-size: 15px;"><a
data-custom-class="link"
href="https://app.termly.io/notify/da32d236-6da0-4eac-b420-b983a26320f4"
rel="noopener noreferrer" target="_blank">data subject access
request</a>.
</span>
</span></span><bdt class="block-component">
<span
data-custom-class="body_text"></span></bdt></span></span>
  <span data-custom-class="body_text">.
For deletion of your data, you may also choose to use the <b>"Delete all my data"</b>
 button available in the user profile.
  </span>
</div>
      </div>
      </div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div>
<br>
<br>
<br>
  </div>
</div>
</template>

<script>
import CircularButton from './circular_button.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    CircularButton
  },
  data() {
    return {}
  },
  props: {
  },
  computed: {

  }, methods: {
  }

}
</script>

<style scoped>
  .margined {
    margin: 1em;
  }

  .toc {
    width: 35vw;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 90vh;
  }

  .grid {
    display: grid;
    grid-template-columns: 35vw 65vw;
    justify-content: center;
  }

  .main {
    margin-left: 35vw;
    width: 60vw;
  }

  .overflow {

    overflow: auto;
  }

  .toc-button {
    position: fixed;
    right: 1em;
    top: 4em;
  }

  @media(min-width: 750px) {
    .toc-button {
      display: none;
    }

  }
  @media(max-width: 750px) {
    .grid {
      display: flex;
      flex-direction: column;
    }

    .toc {
      position: relative;
      width: 75vw;
    }

    .main {
      width: 75vw;
      margin-left: 0;
    }

  }

  .tos-header {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
</style>
